import React from "react";
import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "./yieldTable.css";
import { useEffect, useState, useRef } from "react";
import Pagination from "@mui/material/Pagination";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { API } from "aws-amplify";
import "../../config/amplify";
import { useSelector, useDispatch } from "react-redux";
import { addCompSet, clearCompSet } from "../../Redux/Store/Slice/hotelCompset";
import NoData from "../../Pages/NoData";
import { useLocation } from "react-router-dom";
import { otaDataFunction, otaDemandFunction } from "../../Helper/otaHelper";
import moment from "moment";

const YieldTable = () => {
  const { userData } = useSelector((state: any) => state);

  const dispatch = useDispatch();
  const { hotelDataId } = useSelector((state: any) => state);
  const currentHotelId = hotelDataId.value;
  const { compSet } = useSelector((state: any) => state);
  const [stringArray, setStringArray] = useState<string[]>([]);
  // const stringArray = compSet.value;
  const [cookies, setCookie, removeCookie] = useCookies(["idToken"]);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<string[]>([]);
  const [change, setChange] = useState([]);
  const [hotelData, setHotelData] = useState([]);
  const [acceptButton, setAcceptButton] = useState<string[]>([]);
  const [declineButton, setRejectButton] = useState<string[]>([]);
  const [counter, setCounter] = useState(0);
  const [defaultCompsetType, setDefaultCompsetType] = useState();
  const [hotelName, setHotelName] = useState("");
  const [labelRow, setLabelRow] = useState([
    "Day",
    "Date",
    "Suggested Base Rate",
    "Accept Rate",
    "Decline Rate",
    "Base Rate",
    "Left To Sell",
    "Market Demand",
  ]);
  // const [location, setLocation] = useState()
  const location = useLocation();

  const [media600, setMedia600] = useState(false);
  const [loader, setLoader] = useState(true);
  const [noData, setNoData] = useState(false);
  const [suggestedBaseRate, setSuggestedBaseRate] = useState<any[]>([]);
  const [otaTableData, setOtaTableData] = useState([]);
  const [otaDemandData, setOtaDemandData] = useState([]);
  const idToken = cookies.idToken;
  const date = new Date();

  // Date from today to next 30 days

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const currentDate = `${year}-${month}-${day}`;

  const twoYear = `${year + 2}-${month}-${day}`;

  let yieldData;

  useEffect(() => {
    // setCounter(() => counter + 1);
    if (window.innerWidth >= 800) {
      setMedia600(true);
    } else {
      setMedia600(false);
    }
  });

  useEffect(() => {
    console.log("data", currentHotelId);

    if (currentHotelId) {
      allDataGet();
    }
  }, [counter, currentHotelId]);

  useEffect(() => {
    console.log("compData", currentHotelId);

    setLoader(true);
  }, [currentHotelId]);

  const allDataGet = async () => {
    try {
      const res = await API.get(
        "backend",
        `/suggDays/${currentHotelId}/${currentDate}/${twoYear}`,
        {},
      );
      const filteredData = JSON.parse(res.Payload);

      setData([...filteredData]);
      const currentHotelData = await API.get(
        "yield",
        `/hotel/${currentHotelId}`,
        {},
      );
      const compset =
        typeof currentHotelData.ota.compSet == "undefined"
          ? []
          : currentHotelData.ota.compSet;
      const compsetType = currentHotelData.ota.defaultCompsetType;
      const otaId = currentHotelData.ota.hotelId;

      const name = currentHotelData.info.name;
      setHotelName(name);

      setDefaultCompsetType(compsetType);

      setHotelData(currentHotelData);

      const allSuggestedDates = filteredData.map((data: any) => data.hrDate);

      const demandData = await API.get(
        "yield",
        `/ota/${otaId}/otaDemand/${currentDate}/${twoYear}`,
        {},
      );

      const otaDemanDataFromTable: any = await otaDemandFunction(
        demandData,
        allSuggestedDates,
      );

      setOtaDemandData(otaDemanDataFromTable);

      const stringData = [];
      if (compset.length > 0) {
        const competitors = await API.get(
          "yield",
          `/ota/${otaId}/competitors`,
          {},
        );

        const otaData = await API.get(
          "yield",
          `/ota/${otaId}/otaRates/${currentDate}/${twoYear}`,
          {},
        );

        const otaDataFromTable: any = await otaDataFunction(
          otaData,
          allSuggestedDates,
        );

        setOtaTableData(otaDataFromTable);

        for (let i = 0; i < compset.length; i++) {
          const competitor = competitors.find(
            (element: any) => element.id === compset[i],
          );
          if (typeof competitor !== "undefined") {
            stringData.push(competitor.name);
          }
        }
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      }
      if (!compset || compset.length === 0) {
        setLoader(false);
      }
      console.log(stringData);
      setStringArray(labelRow.concat(stringData));
      dispatch(addCompSet(labelRow.concat(stringData)));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    updateSuggestedBaseRate();
    updateAcceptChange();
    updateRejectChange();
  }, [data, media600, counter, currentPage]);

  registerAllModules();

  const paginate = (e: any, value: any) => {
    setCurrentPage(value);
  };

  // media state for responsiveness of the yieldSheet in the mobile screen and large screen too
  let OTADataFromOtaTable;
  let OTADemandDataFromOtaTable;
  if (media600) {
    const indexOfLastData = currentPage * 8;
    const indexOfFirstData = indexOfLastData - 8;
    yieldData = data.slice(indexOfFirstData, indexOfLastData);
    OTADataFromOtaTable = otaTableData.slice(indexOfFirstData, indexOfLastData);
    OTADemandDataFromOtaTable = otaDemandData.slice(
      indexOfFirstData,
      indexOfLastData,
    );
  } else {
    const indexOfLastData = currentPage * 2;
    const indexOfFirstData = indexOfLastData - 2;
    yieldData = data.slice(indexOfFirstData, indexOfLastData);
    OTADataFromOtaTable = otaTableData.slice(indexOfFirstData, indexOfLastData);
    OTADemandDataFromOtaTable = otaDemandData.slice(
      indexOfFirstData,
      indexOfLastData,
    );
  }

  // Helps us to get the days from date

  const datesData = yieldData.map((data: any) => data.hrDate);

  const getMonthName: string[] = [];
  datesData.forEach((element: any) => {
    getMonthName.push(moment(element).format("DD-MMM-YY"));
  });

  const getDayName: string[] = [];
  datesData.forEach((element: any) => {
    getDayName.push(moment(element).format("dddd"));
  });

  // Date that we want to display on the yield sheet (in mobile 2 days data to be displayed)

  const suggestedBaseRateData = yieldData.map(
    (data: any) => data.suggestedBaseRate,
  );

  const updateAcceptChange = () => {
    const updatedAcceptChange = datesData.map(
      (element: any) => "<button class='accept'>✔</button>",
    );
    setAcceptButton(updatedAcceptChange);
    return updatedAcceptChange;
  };

  const updateRejectChange = () => {
    const updatedRejectChange = datesData.map(
      (element: any) => "<button class='decline'>✖</button>",
    );
    setRejectButton(updatedRejectChange);
  };

  const updateSuggestedBaseRate = () => {
    const suggrates = suggestedBaseRateData.map((data: any) => data);
    setSuggestedBaseRate(suggrates);

    return suggrates;
  };

  const baseRateData = yieldData.map((data: any) => data.actualBaseRate);
  const leftToSellData = yieldData.map(
    (data: any) => data.availability.leftToSell,
  );
  const marketDimandData = OTADemandDataFromOtaTable.map((data: any) =>
    data == undefined ? "-" : Math.round(data * 100) + "%",
  );

  const otaAllData = OTADataFromOtaTable.map((data: any) => data);
  console.log("ota data", otaAllData);

  // apply column logic for all columns (if neede to change the style of full table can be change by col class)

  const column = [];
  for (let i = 0; i <= datesData.length; i++) {
    const colData = {
      tableData: "Accept Rate",
      renderer: "html",
      class: "col",
    };
    column.push(colData);
  }

  // Data we want to display which we are getting from up

  const tableData = [
    getDayName,
    getMonthName,
    suggestedBaseRate,
    acceptButton,
    declineButton,
    baseRateData,
    leftToSellData,
    marketDimandData,
  ];

  // showing "Sold out" at the place of undefined in compset section

  const startCompSet = stringArray.slice(8, stringArray.length);
  console.log(startCompSet);

  startCompSet.forEach((element: any) => {
    let compValueData = [];
    if (defaultCompsetType !== undefined) {
      compValueData = otaAllData.map(function (data: any) {
        const compsetV = data[element]?.[defaultCompsetType];
        let compsetMessage = data[element]?.[defaultCompsetType + "Message"];
        if (compsetMessage === undefined) {
          compsetMessage = "not.retrieved";
        }
        let compsetMessageDisplay = "-";

        switch (compsetMessage) {
          case "general.missing":
            compsetMessageDisplay = "-";
            break;
          case "rates.soldout":
            compsetMessageDisplay = "Sold Out";
            break;
          case "rates.nobar":
            compsetMessageDisplay = "No BAR";
            break;
          case "rates.meal.missing.1":
            compsetMessageDisplay = "No BB";
            break;
          case "rates.meal.missing.5":
            compsetMessageDisplay = "No RO";
            break;
          case "rates.restrictionlos2":
            compsetMessageDisplay = "LOS2";
            break;
          case "rates.restrictionlos3":
            compsetMessageDisplay = "LOS3";
            break;
          case "rates.no_person.1":
            compsetMessageDisplay = "No 1 pax";
            break;
          case "rates.no_person.2":
            compsetMessageDisplay = "No 2 pax";
            break;
          case "rates.onlythirdparty":
            compsetMessageDisplay = "3rd party";
            break;
          case "not.retrieved":
            compsetMessageDisplay = "Sold Out*";
            break;
          default:
            compsetMessageDisplay = "Err";
            console.log("compsetMessage not handled", compsetMessage);
            break;
        }
        const t =
          typeof compsetV != "undefined"
            ? compsetV == 0
              ? compsetMessageDisplay
              : compsetV
            : "-";
        return t;
      });
    }

    tableData.push(compValueData);
  });

  const modifiedArray = tableData.map((innerArray, index) => {
    const firstElement = stringArray[index];
    return [firstElement, ...innerArray];
  });

  const acceptChangesOfSuggestedRate = async (coords: any, TD: any) => {
    const hotelId = currentHotelId;

    const start = datesData[coords.col - 1];
    const end = datesData[coords.col - 1];
    const baseRate = suggestedBaseRate[coords.col - 1];
    const col = coords.col - 1;
    const updatedDatesData = [...acceptButton];
    updatedDatesData[col] = "<button class='accept'>Processing...</button>";
    setAcceptButton(updatedDatesData);

    console.log(baseRate);

    API.post("yield", "/bulkrates", {
      body: {
        hotelId: hotelId,
        start: start,
        end: end,
        baseRate: baseRate,
        col: col,
      },
    })
      .catch((err) => {
        console.error(err);
        toast.error("Base rate changes not approved");
      })
      .then(async () => {
        await API.put("yield", "/removeSuggestedRate", {
          body: {
            end,
            hotelId,
            start,
          },
        })
          .catch((err) => {
            console.error(err);
            toast.error("Base rate changes not approved");
          })
          .then(async () => {
            await toast.promise(
              API.post("backend", "/publishrates", {
                body: {
                  hotelId: hotelId,
                  start: start,
                  end: end,
                },
              }),
              {
                pending: "Publishing...",
                success: "Published",
                error: "Error occurred while publishing",
              },
            );
            setCounter(() => counter + 1);
          });
      });
  };

  const denyChangesOfSuggestedRate = async (coords: any) => {
    const hotelId = currentHotelId;
    const start = datesData[coords.col - 1];
    const end = datesData[coords.col - 1];
    const col = coords.col - 1;
    const updatedDatesData = [...declineButton];
    updatedDatesData[col] = "<button class='decline'>Processing...</button>";
    setRejectButton(updatedDatesData);

    const toastDenyPromise = toast.promise(
      API.put("yield", "/removeSuggestedRate", {
        body: {
          end,
          hotelId,
          start,
          suggestedRate: 0,
        },
      }),
      {
        pending: "Declining suggestion...",
        error: "Error while declining suggestion",
        success: "Declined suggestion",
      },
    );
    toastDenyPromise.then(() => {
      setCounter(() => counter + 1);
    });
  };

  // conditions to be aplied on perticular cell in handsone table

  const cell1 = [];

  for (let i = 0; i <= datesData.length; i++) {
    const cell = {
      row: 1,
      col: i,
      className: "cell1",
    };

    cell1.push(cell);
  }

  for (let i = 0; i <= datesData.length; i++) {
    const cell = {
      row: 5,
      col: i,
      className: "cell1",
    };

    cell1.push(cell);
  }

  for (let i = 0; i <= datesData.length; i++) {
    const cell = {
      row: 6,
      col: i,
      className: "leftToSell",
    };

    cell1.push(cell);
  }

  for (let i = 0; i <= datesData.length; i++) {
    const cell = {
      row: 7,
      col: i,
      className: "marketDemand",
    };

    cell1.push(cell);
  }

  for (let i = 0; i <= datesData.length; i++) {
    for (let j = 0; j <= 1; j++) {
      const cell = {
        row: j,
        col: i,
        editor: false,
      };

      cell1.push(cell);
    }
  }

  for (let i = 0; i <= datesData.length; i++) {
    for (let j = 3; j <= stringArray.length; j++) {
      const cell = {
        row: j,
        col: i,
        editor: false,
      };

      cell1.push(cell);
    }
  }

  const cell = {
    row: 2,
    col: 0,
    editor: false,
  };
  cell1.push(cell);

  const comSetFromMD = stringArray.slice(8, stringArray.length);

  for (let i = 0; i <= datesData.length; i++) {
    for (let y = 8; y <= comSetFromMD.length + 8; y++) {
      const compSet = {
        row: y,
        col: i,
        className: "compSet",
      };
      cell1.push(compSet);
    }
  }

  const changeValue = async (changes: any) => {
    const col = changes?.map((data: any) => data[1]);
    const indCol = col - 1;

    const end = datesData[indCol];
    const hotelId = currentHotelId;
    const start = datesData[indCol];
    const suggestedRate = parseInt(changes?.map((data: any) => data[3]));

    const updatedSuggRateData = [...suggestedBaseRate];

    changes && updatedSuggRateData.splice(indCol, 1, suggestedRate);

    changes && setSuggestedBaseRate(updatedSuggRateData);

    if (changes) {
      if (suggestedRate == baseRateData[indCol]) {
        const updateAndRemove = await API.put("yield", "/removeSuggestedRate", {
          body: {
            end,
            hotelId,
            start,
          },
        });

        setCounter(() => counter + 1);

        return;
      } else {
        const result = await API.put("yield", `/suggestedrate`, {
          body: {
            end,
            hotelId,
            start,
            suggestedRate,
          },
        });

        if (result) {
          setCounter(() => counter + 1);
        }
      }
    }
  };

  return (
    <>
      <h3 style={{ textAlign: "center" }}>{hotelName}</h3>
      {loader ? (
        <div className="spinner">
          <CircularProgress color="secondary" />
        </div>
      ) : data.length === 0 ? (
        <NoData />
      ) : (
        <div style={{ marginTop: "3vh" }}>
          <ToastContainer />

          <HotTable
            settings={{
              afterChange: (changes) => {
                changeValue(changes);
              },
              afterOnCellMouseDown: (Event, coords, TD) => {
                const value = TD.innerHTML;

                if (coords.row === 3 && coords.col != 0) {
                  acceptChangesOfSuggestedRate(coords, TD);
                } else if (coords.row === 4 && coords.col != 0) {
                  denyChangesOfSuggestedRate(coords);
                }
              },
              disableVisualSelection: true,
              wordWrap: true,
              // editor: false,
            }}
            cell={cell1}
            columns={column}
            data={modifiedArray}
            rowHeaders={false}
            licenseKey="non-commercial-and-evaluation"
            stretchH="all"
            rowHeights={40}
            fixedColumnsStart={1}
            colWidths={media600 ? [100] : [60]}
          ></HotTable>
          <br />
          <div className="pegination">
            {media600
              ? data.length > 8 && (
                  <Pagination
                    color="standard"
                    shape="rounded"
                    defaultPage={1}
                    count={Math.ceil(data.length / 8)}
                    page={currentPage}
                    onChange={paginate}
                  />
                )
              : data.length > 2 && (
                  <Pagination
                    color="standard"
                    shape="rounded"
                    defaultPage={1}
                    count={Math.ceil(data.length / 2)}
                    page={currentPage}
                    onChange={paginate}
                  />
                )}

            <br />
          </div>
        </div>
      )}
    </>
  );
};

export default YieldTable;
